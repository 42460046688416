<template>
  <navBar/>
  <div class="center">
    <img alt="cat logo" src="../assets/Abibois_good_format.webp">
    <h1>atelier JUGUET architectes</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'

export default {
  name: 'Home',
  components: {
    navBar
  }
}
</script>

<style lang="scss">
@import '../styles/home';
</style>
