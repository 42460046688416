<template>
  <nav>
    <div class="navbar">
      <div class="nav-container">
          <input class="checkbox" type="checkbox" name="" id="" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>  
        <div class="menu-items">
          <li><a href="#">Accueil</a></li>
          <li><a href="#">Projet</a></li>
          <li><a href="#">Realisation</a></li>
          <li><a href="#">Paille</a></li>
          <li><a href="#">Contact</a></li>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data(){
    return {
      boolValue: false
    }
  },
  methods: {
    get(value) {
      this.boolValue = !this.boolValue

      console.log(this.boolValue)
      if(this.boolValue == true){
        value.target.classList.add('homeSlide');
      }
      else {
        value.target.classList.remove('homeSlide')
      }

      console.log(value.target)
    }
  }
}
</script>
<style scoped lang="scss">
@import '../styles/nav_bar';
</style>